import Alpine from './_snowpack/pkg/alpinejs.js';
window.Alpine = Alpine;
Alpine.start();

document.addEventListener('DOMContentLoaded', (event) => {
    handleCustomDomainName(['securetransfert.fr']);
    //passwordInlineValidation();
})


function handleCustomDomainName(customDomains) { // custom domains list used to display the message
    let injectionContainer = document.querySelector("#sidebar_custom_domain_injection_container");
    let injectionText = document.querySelector("#sidebar_custom_domain_injection_text");
    let injectionUri = document.querySelector("#sidebar_custom_domain_injection_uri");

    const urlParams = new URLSearchParams(window.location.search);
    const redirect_uri = urlParams.get('redirect_uri');

    if (redirect_uri) {
        let hostname = new URL(redirect_uri).hostname;

        let customSpaceParticles = hostname.split('.');
        let customSpaceName = customSpaceParticles[0];
        let customSpaceDomain = customSpaceParticles[1] + '.' + customSpaceParticles[2];
        if (customDomains.includes(customSpaceDomain)) {
            injectionText.setHTML(customSpaceName);
            injectionUri.setHTML(hostname);
        } else {
            injectionContainer.style.display = 'none';
        }
    } else {
        injectionContainer.style.display = 'none';
    }
}

